import React, {useRef, useState} from "react";
import {
    Contact,
    convertToFloorplanCardData,
    DriveGallery,
    FloorplanSectionSkeleton,
    FloorplansSection,
    HomePageSpecialSection,
    PropertyDetails,
    PropertyLocation,
    sortFloorplans,
    TeamSection,
    trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {ActionSection} from "../components/action/ActionSection";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {FeatureSection} from "../components/feature/FeatureSection";
import {MainLayout} from "../components/layout/MainLayout";
import {propertyId} from "../services/DataService";

export const Home: React.FC<PageProps> = ({property, isLoading, contact}) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    return (
        <MainLayout handleRefToFloorPlan={handleRefToFloorPlan}
                    handleRefToMap={handleRefToMap} isMainPage={true}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    setShowApplicationModal={setShowApplicationModal}
                    setShowContactModal={setShowContactModal}
                    contact={contact} property={property}
        >
            <HomePageSpecialSection propertyId={property.id}/>
            <HeroSection handleRefToFloorPlan={handleRefToFloorPlan}/>
            <div className="glasses-background">
                <FeatureSection/>
                <ActionSection handleApplyClicked={() => setShowApplicationModal(true)}
                               handleContactClicked={() => {
                                   setShowContactModal(true);
                                   trackContactClicked("high-grove");
                               }}
                               handleRefToFloorPlan={handleRefToFloorPlan}
                               isLoading={isLoading}
                               floorplans={property.floorplans}/>
            </div>
            <AmenitiesSection/>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Images</span></h2>
                <DriveGallery
                    driveId={property.photosFolderId}
                    initialSize={9}
                    type="simple"
                    showName={false}
                    propertyId={propertyId}
                />
            </div>
            <div ref={floorPlanRef} id="floorplan" className="reference">
            </div>
            {isLoading ?
                <FloorplanSectionSkeleton/> :
                <>{property?.floorplans ? <FloorplansSection
                    propertyId={propertyId}
                    floorplans={
                        sortFloorplans(
                            property.floorplans.filter(floorplan => floorplan.active).map(floorplan => convertToFloorplanCardData(floorplan)
                            ), "featured")
                    }/> : ""}

                </>
            }
            <div ref={mapRef} id="location" className="reference">
            </div>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Location</span></h2>
            </div>
            <PropertyLocation property={property} isLoading={isLoading}
                              handleRefToContact={() => {
                                  setShowContactModal(true);
                                  trackContactClicked("high-grove");
                              }}/>

            <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>

        </MainLayout>
    );
};

export interface PageProps {
    isLoading: boolean;
    property: PropertyDetails;
    contact: Contact;
}
